import { GetResourcesResponse } from '@/schemas/GetResources.schema';

function areAllExternalIntegrationsFalse(data: GetResourcesResponse): boolean {
  return data.items.every((customer) => {
    if (!customer.providers) {
      return true;
    }

    return customer.providers.every((provider) => {
      if (!provider.environments) {
        return true;
      }

      return provider.environments.every(
        (environment) =>
          environment.contract_info.loopass_tact_transfer === false,
      );
    });
  });
}

export { areAllExternalIntegrationsFalse };
