import { AxiosRequestConfig } from 'axios';

import {
  GetDestinationDetailResponse,
  GetDestinationDuplicateUrlMockResponse,
  GetDestinationEmptyMockResponse,
  GetDestinationFullMockResponse,
  GetDestinationMockResponse,
} from './values';

export const getDestinationList = (config: AxiosRequestConfig) => {
  const fullUrl = `${config.baseURL}${config.url}`;
  const url = new URL(fullUrl);

  const paths = url.pathname.split('/');
  const id = paths.pop() as string;

  const endpoint = url.searchParams.get('endpoint');

  if (endpoint) {
    // JSON response for duplicated endpoint url
    if (endpoint === 'https://example.com') {
      return GetDestinationDuplicateUrlMockResponse;
    }

    // JSON response for non duplicated enpoint
    return GetDestinationEmptyMockResponse;
  }

  if (endpoint) {
    // JSON response for duplicated endpoint url
    if (endpoint === 'https://example.com') {
      return GetDestinationDuplicateUrlMockResponse;
    }

    // JSON response for non duplicated enpoint
    return GetDestinationEmptyMockResponse;
  }

  // JSON response for normal destination
  if (id === '6ed426f4-392e-4a04-8ae3-ef767add306c') {
    return GetDestinationMockResponse;
  }

  // JSON response for full destination
  if (id === '066ba03d-74e0-4e50-a62b-afce7d3d39c1') {
    return GetDestinationFullMockResponse;
  }

  // JSON response for empty destination
  if (id === 'aaccb5df-0f4c-48d4-9a37-f9e3ed915595') {
    return GetDestinationEmptyMockResponse;
  }

  // JSON response for invalid destination
  return GetDestinationEmptyMockResponse;
};

export const getDestination = () => {
  return GetDestinationDetailResponse;
};
