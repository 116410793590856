import { Box } from '@chakra-ui/react';
import type { Property as CSSProperty } from 'csstype';

interface Props {
  children?: React.ReactNode;
  flexGrow?: number;
  px?: string;
  py?: string;
  overflowY?: CSSProperty.OverflowY;
}

export default function ContentBox(props: Props) {
  const { children, flexGrow, px, py, overflowY } = props;

  return (
    <Box
      flexGrow={flexGrow ?? 1}
      bg="white"
      borderRadius="12px"
      width="100%"
      px={px ?? '32px'}
      py={py ?? '24px'}
      overflowY={overflowY ?? flexGrow === 0 ? undefined : 'auto'}
    >
      {children}
    </Box>
  );
}
