import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { TACT_API_BASE_URL } from '@/constants';

import { getDestination, getDestinationList } from './destination/get';
import { patchDestination } from './destination/patch';
import { postDestination } from './destination/post';

interface MockAdapterOptions {
  delayResponse?: number;
  onNoMatch?: 'passthrough' | 'throwException';
}

const mockAdapterConfig: MockAdapterOptions = {
  // To simulate response delays
  delayResponse: 1250,
  onNoMatch: 'passthrough',
};

export const isMockingEnabled = () => {
  const isApiMockingEnabled =
    process.env.NEXT_PUBLIC_API_MOCKING_ENABLED === 'true';

  return isApiMockingEnabled;
};

export default function applyMockAdapter(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, mockAdapterConfig);

  const uuid = '([\\w]{8}(-[\\w]{4}){3}-[\\w]{12})';

  const destinationUri = `${TACT_API_BASE_URL}/destination`;
  const destinationRegex = new RegExp(`(${destinationUri}/)(\\w*)`, 'g');

  const getDestinationListRegex = new RegExp(
    `(${destinationUri}/)(${uuid})(?:\\?.+)?/?$`,
    'g',
  );

  const getDestinationRegex = new RegExp(
    `(${destinationUri}/)(${uuid}/)(${uuid})/?$`,
    'g',
  );

  const postDestinationRegex = new RegExp(`(${destinationUri}/)(\\w*)`, 'g');

  mock
    .onGet(getDestinationListRegex)
    .reply((config) => [200, getDestinationList(config)]);

  mock.onGet(getDestinationRegex).reply(() => [200, getDestination()]);

  mock
    .onPost(postDestinationRegex)
    .reply((config) => [201, postDestination(config)]);

  mock
    .onPatch(postDestinationRegex)
    .reply((config) => [200, patchDestination(config)]);

  mock.onDelete(destinationRegex).reply(() => [200, {}]);
}
