import { Image, Stack, Text } from '@chakra-ui/react';

interface Props {
  name: string;
}

export default function ItemProviderName({ name }: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0}
      px={2}
      py={1}
      lineHeight="19.44px"
    >
      <Image
        src="/icons/sidebar-arrow.svg"
        alt="→"
        width={0}
        height={0}
        cursor="default"
        style={{ width: '15px', height: '15px' }}
      />
      <Text
        pl={1}
        fontSize="12.15px"
        color="theme.text.primary"
        fontWeight="medium"
        lineHeight="19.44px"
        minHeight="19.44px"
      >
        {name}
      </Text>
    </Stack>
  );
}
