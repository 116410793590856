import { Property as CSSProperty } from 'csstype';
import type { ReactNode } from 'react';

import { Box, Heading, VStack } from '@chakra-ui/react';

import Button from './button';
import { ArrowBackIcon } from './icons';

interface Props {
  isCreateOrUpdate?: boolean;
  contentOverflowY?: CSSProperty.OverflowY;
  isRestrictedHeight?: boolean;

  hasBackButton?: boolean;
  backAction?: () => void;

  hasCancel?: boolean;
  cancelAction?: () => void;

  hasTopRightButton?: boolean;
  topRightButtonLabel?: string;
  topRightButtonDisabled?: boolean;
  topRightButtonAction?: () => void;
  topRightButtonLoading?: boolean;

  hasBottomRightButton?: boolean;
  bottomRightButtonLabel?: string;
  bottomRightButtonDisabled?: boolean;
  bottomRightButtonAction?: () => void;
  bottomRightButtonIsAlert?: boolean;
  bottomRightButtonLoading?: boolean;

  heading?: string;
  subheading?: ReactNode;

  children: ReactNode;
}

export default function Content(props: Props) {
  const {
    isCreateOrUpdate,
    contentOverflowY,
    isRestrictedHeight,

    hasBackButton,
    backAction,

    hasCancel,
    cancelAction,

    hasTopRightButton,
    topRightButtonLabel = 'Submit',
    topRightButtonDisabled,
    topRightButtonAction,
    topRightButtonLoading,

    hasBottomRightButton,
    bottomRightButtonLabel = 'Default',
    bottomRightButtonDisabled,
    bottomRightButtonAction,
    bottomRightButtonIsAlert,
    bottomRightButtonLoading,

    heading,
    subheading,

    children,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="1536px"
      marginLeft="auto"
      marginRight="auto"
      style={{
        height: '100%',
      }}
    >
      {heading && (
        <Box
          width="100%"
          maxWidth={isCreateOrUpdate ? '800px' : undefined}
          data-testid="content-inner-box-heading"
        >
          <Box display="flex" alignItems="center" mb="24px">
            {hasBackButton && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="24px"
                height="24px"
                cursor={backAction ? 'pointer' : 'default'}
                marginRight="8px"
                my="12px"
                onClick={backAction}
                data-testid="content-cancel-arrow"
              >
                <ArrowBackIcon width="23px" height="22px" />
              </Box>
            )}

            <Heading
              fontWeight="bold"
              fontSize="xl"
              lineHeight="short"
              flexGrow={1}
            >
              {heading}
            </Heading>

            {hasCancel && (
              <Button
                variant="link"
                fontWeight="bold"
                fontSize="md"
                onClick={cancelAction}
              >
                キャンセル
              </Button>
            )}

            {hasTopRightButton && (
              <Button
                minHeight="48px"
                ml="16px"
                fontWeight="bold"
                isLoading={topRightButtonLoading}
                isDisabled={topRightButtonDisabled}
                onClick={topRightButtonAction}
              >
                {topRightButtonLabel}
              </Button>
            )}
          </Box>
          <Box mb={isCreateOrUpdate ? '24px' : '16px'}>
            {subheading && <Box flexGrow={1}>{subheading}</Box>}
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexGrow={1}
        width="100%"
        maxWidth={isCreateOrUpdate ? '800px' : undefined}
        data-testid="content-inner-box-content"
        style={{
          // tentative
          maxHeight: isRestrictedHeight ? 'calc(100% - 107px)' : undefined,
          height: isRestrictedHeight ? '100%' : undefined,
        }}
      >
        <VStack
          spacing="24px"
          width="100%"
          overflowY={contentOverflowY ?? isCreateOrUpdate ? undefined : 'auto'}
        >
          {children}
        </VStack>
      </Box>

      {hasBottomRightButton && (
        <Box width="100%" maxWidth={isCreateOrUpdate ? '800px' : undefined}>
          <Box
            display="flex"
            alignItems="center"
            mt="24px"
            justifyContent="flex-end"
          >
            <Button
              minHeight="48px"
              ml="16px"
              mb="12px"
              isDisabled={bottomRightButtonDisabled}
              isLoading={bottomRightButtonLoading}
              onClick={bottomRightButtonAction}
              isAlert={bottomRightButtonIsAlert}
            >
              {bottomRightButtonLabel}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
