import { AxiosRequestConfig } from 'axios';
import { CreateDestinationResponse } from './values';

export const patchDestination = (
  config: AxiosRequestConfig,
): CreateDestinationResponse => {
  const body = config.data;

  return {
    ...body,
  };
};
