import axios from 'axios';

import applyMockAdapter, { isMockingEnabled } from '@/mocks';

const tactAxiosInstance = axios.create();

if (isMockingEnabled()) {
  applyMockAdapter(tactAxiosInstance);
}

export default tactAxiosInstance;
