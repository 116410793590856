import { forwardRef } from 'react';

import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

interface CustomButtonProps extends ButtonProps {
  isAlert?: boolean;
}

const linkColors = {
  primary: 'theme.button_text.primary',
  disabled: 'theme.button_text.disabled',
  loading: 'theme.button_text.loading',
  hover: 'theme.button_text.hover',
  pressed: 'theme.button_text.pressed',
};

const dangerColors = {
  primary: 'theme.button_red.primary',
  disabled: 'theme.button_red.disabled',
  loading: 'theme.button_red.loading',
  hover: 'theme.button_red.hover',
  pressed: 'theme.button_red.pressed',
};

const defaultColors = {
  primary: 'theme.button.primary',
  disabled: 'theme.button.disabled',
  loading: 'theme.button.loading',
  hover: 'theme.button.hover',
  pressed: 'theme.button.pressed',
};

const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (props, ref) => {
    const { children, isDisabled, isLoading, variant, isAlert, ...otherProps } =
      props;

    const isLink = variant === 'link';
    const colors = isLink ? linkColors : isAlert ? dangerColors : defaultColors;

    return (
      <ChakraButton
        minHeight="46px"
        minWidth="100px"
        fontWeight="bold"
        fontSize="md"
        px="16px"
        py="12px"
        borderRadius="4px"
        ref={ref}
        color={isLink ? colors.primary : '#fff'}
        style={{
          opacity: 1,
        }}
        bg={
          isLink
            ? undefined
            : isLoading
              ? colors.loading
              : isDisabled
                ? colors.disabled
                : colors.primary
        }
        _hover={{
          textDecoration: undefined,
          color: isLink ? colors.hover : undefined,
          bg: isLink
            ? undefined
            : isLoading
              ? colors.loading
              : isDisabled
                ? colors.disabled
                : colors.hover,
        }}
        _active={{
          color: isLink ? colors.pressed : undefined,
          bg: isLink
            ? undefined
            : isLoading
              ? colors.loading
              : isDisabled
                ? colors.disabled
                : colors.pressed,
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        loadingText={children}
        variant={variant}
        {...otherProps}
      >
        {children}
      </ChakraButton>
    );
  },
);

export default Button;
