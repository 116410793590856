import '@fontsource/noto-sans-jp/japanese-400.css';
import '@fontsource/noto-sans-jp/japanese-500.css';
import '@fontsource/noto-sans-jp/japanese-700.css';

import 'modern-normalize/modern-normalize.css';

import { ChakraProvider, Flex, Progress } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';

import useAuthGuard from '@/hooks/useAuthGuard';

import { theme } from './_theme';
import { Footer } from './common';
import Navbar from './common/navbar';

export default function App({ Component, pageProps }: AppProps) {
  const { isLoading, sessionToken, userType, email, authService } =
    useAuthGuard();

  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        {sessionToken && !isLoading && (
          <Navbar
            auth={!!sessionToken}
            AuthService={authService}
            email={email}
            userType={userType || ''}
          />
        )}

        <Flex>
          {sessionToken && !isLoading ? (
            <Component
              {...pageProps}
              authSession={sessionToken}
              authService={authService}
              userType={userType}
            />
          ) : (
            <Progress size="xs" isIndeterminate />
          )}
        </Flex>

        {sessionToken && !isLoading && <Footer />}
      </ChakraProvider>
    </RecoilRoot>
  );
}
