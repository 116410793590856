import { HStack, Skeleton, Text } from '@chakra-ui/react';

import { MAX_DESTINATION_COUNT, MAX_SWITCHER_COUNT } from '@/constants';

type SubHeadingProps = {
  isLoading?: boolean;
  totalDestinationCount?: number;
  totalSwitcherCount?: number;
};

export default function SubHeading(props: SubHeadingProps) {
  const {
    isLoading = false,
    totalDestinationCount = 0,
    totalSwitcherCount = 0,
  } = props;

  return (
    <HStack spacing="16px" display="flex" alignItems="flex-start">
      <Text
        fontSize="sm"
        color="theme.text.secondary"
        display="flex"
        lineHeight="short"
      >
        <span>外部連携先設定数 : </span>
        {isLoading ? (
          <Skeleton
            as="span"
            height="18.2px"
            width="45px"
            marginLeft="4px"
            data-testid="skeleton-total-destination"
          />
        ) : (
          <span style={{ marginLeft: '4px', width: '45px' }}>
            {`${totalDestinationCount} / ${MAX_DESTINATION_COUNT}`}
          </span>
        )}
      </Text>
      <Text
        fontSize="sm"
        color="theme.text.secondary"
        display="flex"
        lineHeight="short"
      >
        <span>振り分け先設定数 : </span>
        {isLoading ? (
          <Skeleton
            as="span"
            height="18.2px"
            width="45px"
            marginLeft="4px"
            data-testid="skeleton-total-switcher"
          />
        ) : (
          <span style={{ marginLeft: '4px', width: '45px' }}>
            {`${totalSwitcherCount} / ${MAX_SWITCHER_COUNT}`}
          </span>
        )}
      </Text>
    </HStack>
  );
}
