import { useRef } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import Button from './button';

interface Props {
  isLoading?: boolean;
  title: string;
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

export default function Dialog(props: Props) {
  const { isLoading, title, message, isOpen, onClose, onSubmit } = props;

  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent textAlign="center" p="40px" borderRadius="12px">
        <AlertDialogHeader fontWeight="bold" fontSize="lg" pt={0} px={0}>
          {title}
        </AlertDialogHeader>

        <AlertDialogBody fontSize="md" pt="8px" pb="24px" px={0}>
          {message}
        </AlertDialogBody>

        <AlertDialogFooter
          gap="32px"
          justifyContent="center"
          pt="16px"
          pb={0}
          px={0}
        >
          <Button
            ref={cancelRef}
            onClick={onClose}
            variant="link"
            color="theme.text.secondary"
          >
            キャンセル
          </Button>
          <Button isAlert onClick={onSubmit} isLoading={isLoading}>
            削除する
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
