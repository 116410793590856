import { extendTheme } from '@chakra-ui/react';

import menuConfig from './common/theme/menu.theme';
import switchConfig from './common/theme/switch.theme';

export const config = {
  styles: {
    global: {
      '.customResizeHandler': {
        color: '#4A5568',
        cursor: 'e-resize',
        position: 'absolute',
        right: 0,
      },

      '.customResizeHandler:hover': {
        textShadow: '-1px 0px #4A5568',
      },

      'th.stickyColumnLeft': {
        zIndex: 2,
        position: 'sticky',
        left: 0,
        backgroundColor: '#F4FBFC',
      },

      'td.stickyColumnLeft': {
        zIndex: 1,
        position: 'sticky',
        left: 0,
        backgroundColor: '#FFFFFF',
      },

      '.hasScrollLeft th.stickyColumnLeft::after, .hasScrollLeft td.stickyColumnLeft::after':
        {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '1px',
          backgroundColor: '#EAECED',
          zIndex: 1,
          borderRadius: '0 0 6px 6px',
          boxShadow: '0px 5px 7px rgba(0,0,0,.3)',
        },

      'th.stickyColumnRight': {
        zIndex: 2,
        position: 'sticky',
        right: 0,
        backgroundColor: '#F4FBFC',
      },

      'td.stickyColumnRight': {
        zIndex: 1,
        position: 'sticky',
        right: 0,
        backgroundColor: '#FFFFFF',
      },

      'th.stickyColumnRight::before, td.stickyColumnRight::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '1px',
        backgroundColor: '#EDEDED',
        zIndex: 1,
        borderRadius: '0 0 6px 6px',
        boxShadow: '0px 5px 6px rgba(0,0,0,.25)',
      },

      '.hasScrollRight th.stickyColumnRight::before, .hasScrollRight td.stickyColumnRight::before':
        {
          content: 'none',
        },
    },
  },
  fonts: {
    body: "'Noto Sans JP', sans-serif",
    heading: "'Noto Sans JP', sans-serif",
  },
  fontSizes: {
    xs: '11px',
    sm: '13px',
    md: '14px', // needs to be explicitly added to props
    lg: '18px',
    xl: '24px',
    xxl: '32px',
  },
  fontWeights: {
    normal: 400, // this is marked as "regular" in figma
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: '1.6em',
    short: '1.4em',
    base: '1.6em',
  },
  colors: {
    brand: {
      500: '#5ba7b8', // default
      600: '#417884', // on hover
    },
    danger: {
      500: '#e85353', // default
      600: '#b44040', // on hover
    },
    theme: {
      navbar: '#325d67',
      sidebar: {
        background: '#f3f4f4',
        primary: '#5ba7b8',
        hover: '#4f8b9a',
      },
      button: {
        primary: '#469db1',
        disabled: '#9ac6d0',
        loading: '#dadbdb',
        hover: '#7bc1d0',
        pressed: '#488b9a',
      },
      button_red: {
        primary: '#e85353',
        disabled: '#eba1a1',
        loading: '#dadbdb',
        hover: '#f19494',
        pressed: '#d34141',
      },
      button_text: {
        primary: '#656666',
        disabled: '#9ac5cf',
        loading: '#b7b8b8',
        hover: '#7bc1d0',
        pressed: '#2d2e2e',
      },
      text: {
        primary: '#232525',
        secondary: '#656666',
      },
      table: {
        heading: '#f4fbfc',
        rowHover: '#fafafa',
      },
      main: {
        // This is for colorizing the main content borders
        // 本番 (production) & テスト (development)
        development: '#ddf2f4',
        production: '#fafafa',
      },
    },
  },
  components: {
    Menu: menuConfig,
    Switch: switchConfig,
    Modal: {
      baseStyle: {
        dialog: {
          minWidth: '800px',
          maxWidth: '800px',
        },
      },
    },
    Progress: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Text: {
      baseStyle: {
        color: '#232525',
      },
      variants: {
        // used as <Text variant="secondary">
        secondary: {
          color: '#656666',
        },
      },
    },
  },
};

export const theme = extendTheme(config);
