import { AxiosRequestConfig } from 'axios';
import { CreateDestinationResponse } from './values';

export const postDestination = (
  config: AxiosRequestConfig,
): CreateDestinationResponse => {
  const body = config.data;
  const envId = config.url!.split('/').pop() as string;

  return {
    ...body,
    id: '7a30d735-2921-4919-a1d4-13d1d1a3380d',
    env_id: envId,
  };
};
