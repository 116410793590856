import { array, boolean, nativeEnum, object, string } from 'zod';

enum EnvironmentTypes {
  development = 'development',
  production = 'production',
}

export interface ContractInfo {
  loopass_tact_transfer: boolean;
}

export interface Environment {
  id: string;
  type: `${EnvironmentTypes}`;
  line_account_name: string;
  contract_info: ContractInfo;
}

export interface Environments extends Array<Environment> {}

export interface Provider {
  id: string;
  name: string;
  environments?: Environments;
}

export interface Providers extends Array<Provider> {}

export interface Item {
  id: string;
  name: string;
  providers?: Providers;
}

export interface Items extends Array<Item> {}

export interface GetResourcesResponse {
  items: Items;
}

export const GetResourcesSchema = object({
  items: array(
    object({
      id: string(),
      name: string(),
      providers: array(
        object({
          id: string(),
          name: string(),
          environments: array(
            object({
              id: string(),
              type: nativeEnum(EnvironmentTypes),
              line_account_name: string(),
              contract_info: object({
                loopass_tact_transfer: boolean(),
              }),
            }),
          ).optional(),
        }),
      ).optional(),
    }),
  ),
});
