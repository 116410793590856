import { Box } from '@chakra-ui/react';

interface Props {
  name: string;
}

export default function ItemCompanyName({ name }: Props) {
  return (
    <Box
      as="p"
      minHeight="18.48px"
      fontWeight="bold"
      fontSize="13.2px"
      lineHeight="18.48px"
      cursor="default"
      color="theme.text.primary"
    >
      {name}
    </Box>
  );
}
