'use client';

import { forwardRef } from 'react';

import { Control, Controller } from 'react-hook-form';

import {
  Switch as ChakraSwitch,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  SwitchProps,
  Text,
} from '@chakra-ui/react';

interface BaseProps extends SwitchProps {
  id: string;
  label: string;
  helperText?: string;
  errorMessage?: string;
  isError?: boolean;
}

interface Props extends BaseProps {
  control: Control<any>;
  onChangeValidation?: (...args: any[]) => boolean;
  name: string;
}

export const UncontrolledSwitch = forwardRef((props: BaseProps, ref) => {
  const {
    label,
    helperText,
    errorMessage,
    isChecked,
    isError,
    isRequired,
    isDisabled,
    ...otherProps
  } = props;

  return (
    <FormControl
      display="block"
      isInvalid={isError}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <FormLabel mb="15px" fontSize="md">
        {label}
      </FormLabel>

      <HStack display="flex" ml="8px" spacing={2}>
        <ChakraSwitch isChecked={isChecked} ref={ref} {...otherProps} />
        <Text opacity={isDisabled ? '0.4' : undefined}>
          {isChecked ? 'ON' : 'OFF'}
        </Text>
      </HStack>

      {!isError ? (
        <FormHelperText fontSize="xs" mt={0}>
          {helperText}
        </FormHelperText>
      ) : (
        <FormErrorMessage fontSize="xs" mt={0}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
});

const Switch = (props: Props) => {
  const { control, name, id, label, onChangeValidation, ...otherProps } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <UncontrolledSwitch
          ref={ref}
          id={id}
          label={label}
          isChecked={value}
          onChange={(val: any) => {
            if (onChangeValidation) {
              const isValid = onChangeValidation(val);

              return isValid ? onChange(val) : null;
            }

            return onChange(val);
          }}
          {...otherProps}
        />
      )}
    />
  );
};

export default Switch;
