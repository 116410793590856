import useSWR, { Fetcher } from 'swr';
import { ZodType } from 'zod';

import { getData as getEnvironmentData } from '../lib/environment-api';
import { getData as getTactData } from '../lib/tact-api';

export interface Params {
  type: 'env' | 'tact';
  endpoint: string | null;
  schema: ZodType<unknown>;
  idToken: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher: Fetcher<any, any> = async (params: Params) => {
  const { endpoint, schema, idToken, type } = params;

  if (endpoint && idToken !== '') {
    if (type === 'env') {
      const validatedData = await getEnvironmentData(endpoint, schema, idToken);

      return validatedData;
    }

    if (type === 'tact') {
      const validatedData = await getTactData(endpoint, schema, idToken);
      return validatedData;
    }

    console.error('Request type is invalid');
    throw new Error('Request type is invalid');
  }

  return null;
};

const useGetRequest = <T>(
  type: 'env' | 'tact',
  endpoint: string | null,
  schema: ZodType<T>,
  idToken: string | null,
) => {
  const { data, error, mutate } = useSWR<T, object>(
    { endpoint, schema, idToken, type },
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useGetRequest;
