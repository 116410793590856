/* eslint-disable no-unused-expressions */
import type {
  TableBodyProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import {
  TableContainer as ChakraTableContainer,
  Progress,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

interface TableProgressProps {
  colSpan: number;
}

export const TableContainer = (props: TableProps) => {
  const { children, ...otherProps } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    const tableRefCurrent = tableRef.current;

    const handleScroll = () => {
      if (tableContainer) {
        const isRightMost =
          tableContainer.scrollLeft + tableContainer.clientWidth >=
          tableContainer.scrollWidth - 5;

        const isLeftMost = tableContainer.scrollLeft > 0;

        if (isRightMost) {
          tableContainer.classList.add('hasScrollRight');
        } else {
          tableContainer.classList.remove('hasScrollRight');
        }

        if (isLeftMost) {
          tableContainer.classList.add('hasScrollLeft');
        } else {
          tableContainer?.classList.remove('hasScrollLeft');
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      handleScroll(); // Check scroll position on resize as well
    });

    tableContainer?.addEventListener('scroll', handleScroll);
    tableRefCurrent && resizeObserver.observe(tableRefCurrent);

    return () => {
      tableContainer?.removeEventListener('scroll', handleScroll);
      tableRefCurrent && resizeObserver.unobserve(tableRefCurrent);
    };
  }, []);

  return (
    <ChakraTableContainer
      style={{
        maxWidth: '100%',
        height: '100%',
        overflowY: 'auto',
        minHeight: '170px',
      }}
      ref={tableContainerRef}
    >
      <Table variant="simple" role="table" {...otherProps} ref={tableRef}>
        {children}
      </Table>
    </ChakraTableContainer>
  );
};

export const TableHeader = (props: TableHeadProps) => {
  const { children, ...otherProps } = props;

  return (
    <Thead
      bg="theme.table.heading"
      fontWeight="medium"
      color="theme.text.secondary"
      {...otherProps}
    >
      {children}
    </Thead>
  );
};

export const TableBody = (props: TableBodyProps) => {
  const { children, ...otherProps } = props;

  return (
    <Tbody fontSize="md" color="theme.text.primary" {...otherProps}>
      {children}
    </Tbody>
  );
};

export const TableRow = (props: TableRowProps) => {
  const { children, ...otherProps } = props;

  return <Tr {...otherProps}>{children}</Tr>;
};

export const TableProgress = (props: TableProgressProps) => {
  const { colSpan } = props;

  return (
    <Tr>
      <Td colSpan={colSpan} padding={0}>
        <Progress size="xs" isIndeterminate />
      </Td>
    </Tr>
  );
};

export const TableHeading = (props: TableColumnHeaderProps) => {
  const { children, ...otherProps } = props;

  return (
    <Th
      px="24px"
      py="14px"
      fontSize="md"
      letterSpacing={0}
      lineHeight="short"
      paddingRight={0}
      backgroundColor="#f4fbfc"
      position="sticky"
      top="0"
      {...otherProps}
    >
      {children}
    </Th>
  );
};

export const TableColumn = (props: TableCellProps) => {
  const { children, ...otherProps } = props;

  return (
    <Td px="24px" py="12px" {...otherProps}>
      {children}
    </Td>
  );
};

export default {
  Header: TableHeader,
  Body: TableBody,
  Row: TableRow,
  Heading: TableHeading,
  Column: TableColumn,
  Progress: TableProgress,
};
