import { AxiosError } from 'axios';
import { ZodType } from 'zod';

import { isMockingEnabled } from '@/mocks';

import { TACT_API_BASE_URL, TACT_API_KEY } from '../constants';
import tactAxiosInstance from './tact-axios';

export const getData = async (
  endpoint: string,
  schema: ZodType<unknown>,
  bearerToken: string,
) => {
  try {
    if (isMockingEnabled()) {
      console.warn('API Mocking for Tact API is enabled');
      console.log('Endpoint called: GET:', endpoint); // eslint-disable-line
    }

    const response = await tactAxiosInstance.get(endpoint, {
      baseURL: TACT_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        'x-api-key': TACT_API_KEY,
      },
    });

    if (isMockingEnabled()) {
      console.log('Endpoint response: GET:', response); // eslint-disable-line
    }

    const validatedData = schema.parse(response.data);

    return validatedData;
  } catch (error) {
    if (endpoint.includes('/resources')) {
      throw error;
    }

    console.error('API request or validation failed:', error);

    if (error instanceof AxiosError) {
      if (error.response?.status === 404) {
        window.location.href = '/404';
      }
    }

    throw error;
  }
};

export const postData = async (
  endpoint: string,
  data: object,
  bearerToken: string,
) => {
  try {
    if (isMockingEnabled()) {
      console.warn('API Mocking for Tact API is enabled');
      console.log('Endpoint called: POST:', endpoint, data); // eslint-disable-line
    }

    const response = await tactAxiosInstance.post(endpoint, data, {
      baseURL: TACT_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        'x-api-key': TACT_API_KEY,
      },
    });

    if (isMockingEnabled()) {
      console.log('Endpoint response: POST:', response); // eslint-disable-line
    }

    return response;
  } catch (error) {
    console.error('API request failed:', error);

    throw error;
  }
};

export const patchData = async (
  endpoint: string,
  data: object,
  bearerToken: string,
) => {
  try {
    if (isMockingEnabled()) {
      console.warn('API Mocking for Tact API is enabled');
      console.log('Endpoint called: PATCH:', endpoint, data); // eslint-disable-line
    }

    const response = await tactAxiosInstance.patch(endpoint, data, {
      baseURL: TACT_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        'x-api-key': TACT_API_KEY,
      },
    });

    if (isMockingEnabled()) {
      console.log('Endpoint response: PATCH:', response); // eslint-disable-line
    }

    return response;
  } catch (error) {
    console.error('API request failed:', error);

    throw error;
  }
};

export const deleteData = async (endpoint: string, bearerToken: string) => {
  try {
    if (isMockingEnabled()) {
      console.warn('API Mocking for Tact API is enabled');
      console.log('Endpoint called: DELETE:', endpoint); // eslint-disable-line
    }

    const response = await tactAxiosInstance.delete(`${endpoint}`, {
      baseURL: TACT_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        'x-api-key': TACT_API_KEY,
      },
    });

    if (isMockingEnabled()) {
      console.log('Endpoint response: DELETE:', response); // eslint-disable-line
    }

    return response;
  } catch (error) {
    console.error('API request failed:', error);

    throw error;
  }
};
